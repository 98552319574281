import React from "react";
import { Link } from "gatsby";

let GenerateLink = (props) => (
    <Link className="hover:text-green-800 hover:font-bold" to={props.url}>{props.title}</Link>
)
export default () => (
    <div className="w-full text-center border-t border-green-900 b-w p-4">
        <p><GenerateLink url="/" title="Home"/> | <GenerateLink url="/aboutMe/" title="About Me"/> | <GenerateLink url="/testimonials/" title="Testimonials"/> | <GenerateLink url="/faq/" title="FAQ"/> | <GenerateLink url="/contact/" title="Contact"/> | <GenerateLink url="/services/" title="Services"/> </p>
        <p>&copy; Marcus Moran, 2019</p>
    </div>
)