import React from "react";
import {Helmet} from "react-helmet";
import Header from "../components/header";
import Footer from "../components/footer";
import logo from "../imgs/logo_transparent.png"

let MobileLogo = () => (
    <div className="w-full md:hidden">  
        <div className="max-w-xs mx-auto bg-blue-800 rounded h-full my-6">
            <img className="mx-auto shadow-2xl rounded border-2 border-gray-900" src={logo} alt="Logo" />
        </div>
            
    </div>
)
export default ({title, des, children}) => (
    <div lang="en">
        <Helmet>
            <meta charSet="utf-8"/>
            <title>MM Insurance | {title}</title>
            <meta name="description" content={des} />
            <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:light,regular,medium,thin,italic,mediumitalic,bold" rel="stylesheet"></link>           
        </Helmet>
        <Header/>
        <MobileLogo/>
        {children}
        <Footer />
    </div>
)