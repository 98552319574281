import React from "react";
import { Link } from "gatsby";

import '../styles/header.css';
import logo from "../imgs/logo_transparent.png";

let BuildLinks = (props) => (
    <Link to={props.url}>
        <div className="block w-full md:w-auto hover:bg-gray-500 hover:text-white border-b-2 border-transparent md:hover:bg-transparent md:hover:text-black md:hover:border-gray-600 md:inline-block  p-4">
            {props.text}
        </div>
    </Link>
    
    
)

export default class Header extends React.Component{
    constructor(props){
        super(props);
        this.state={disabled:1}
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(){
        this.setState({disabled:Math.pow(0,this.state.disabled)})
    }

    render(){
        let show = this.state.disabled ? "w-full inline-block flex-grow md:flex md:flex-grow-0 md:items-center md:w-auto md:h-auto hidden" : "w-full inline-block flex-grow md:flex-grow-0 md:items-center md:w-auto h-screen md:h-auto"
        
        return <div className="sticky-webkit top-0 w-full z-10">
            <nav className="flex items-center justify-between flex-wrap bg-gray-200 md:bg-opacity-editor py-0">
                <div className=" hidden md:flex items-center flex-shrink-0 text-gray-700 text-center px-2 m-4 ">
                    <div className="bg-blue-800 rounded shadow-2xl">
                        <img className="w-48 lg:w-64 h-full rounded border-2 border-gray-900" src={logo} alt="MM Insurance" />
                    </div>
                </div>
                
                <div onClick={this.handleClick} className="opactiy-100 flex items-center flex-shrink-0 text-gray-700 text-center bg-gray-400 px-2 py-1 m-1 md:hidden">
                    <span className="font-semibold text-xl">Menu</span>
                </div>
                
                <div className={show}>
                    <div className="text-sm lg:text-lg md:flex-grow bg-gray-200 md:bg-transparent md:pr-6">
                        <BuildLinks url="/" text="Home"/>
                        <BuildLinks url="/aboutMe/" text="About Me"/>
                        <BuildLinks url="/testimonials/" text="Testimonials"/>
                        <BuildLinks url="/faq/" text="FAQ"/>
                        <BuildLinks url="/services/" text="Services"/>
                        <BuildLinks url="/contact/" text="Contact"/>
                    </div>
                </div>
            </nav>
        </div>
    }
}
